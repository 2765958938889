import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Lottie from "lottie-react"
import invite from "../images/invite.json"
import hearts from "../images/hearts.json"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Gallery from "react-photo-gallery"
import { photos } from "../images/photos"
import AddToCalendarHOC from "react-add-to-calendar-hoc"
import moment from "moment"

const ATCDropdown = args => (
  <ul className="atc-dropdown">
    {args.children.map((link, i) => (
      <li key={i}>{link}</li>
    ))}
  </ul>
)

const ATCWrapper = args => (
  <a onClick={args.onClick} className="atc-item" href="/">
    {args.children}
  </a>
)

const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown)

const wedding = {
  title: "The Kellgren/Bubley Wedding",
  description:
    "Megan and Corey will be getting hitched at the South Congress Hotel in Austin, Texas on February 12, 2022 @ 5pm",
  location: "South Congress Hotel, Austin, TX",
  startTime: "2022-02-12T17:00:00-04:00",
  endTime: "2022-02-12T23:59:00-04:00",
}

const startDatetime = moment().utc().add(2, "days")
const endDatetime = startDatetime.clone().add(2, "hours")
const duration = moment.duration(endDatetime.diff(startDatetime)).asHours()
const event = {
  description:
    "Megan and Corey will be getting hitched at the South Congress Hotel in Austin, Texas on February 12, 2022 @ 5pm - reception to follow.",
  duration,
  endDatetime: endDatetime.format("20220212T235900CST"),
  location: "South Congress Hotel, Austin, TX",
  startDatetime: startDatetime.format("20220212T170000CST"),
  title: "The Kellgren/Bubley Wedding",
}

const IndexPage = () => (
  <div className="text-center text-sm my-2">
  <Layout>
    <SEO title="Megan & Corey | 2/12/22" />
    
    <div className="text-center">
      <Lottie className="md:-mb-28 mx-auto w-64" animationData={hearts} />
      <h1 className="text-8xl sm:-ml-16">
        Megan{" "}
        <span className="md:mx-20">
          <span className="md:hidden">& </span>
        </span>
        Corey
      </h1>
      <p style={{ fontFamily: "bad script" }} className="mt-10 md:mt-5">
        are getting married
      </p>
      <div>
        <p
          style={{ fontFamily: "bad script" }}
          className="text-2xl font-semibold mt-10"
        >
          📅 February 12th, 2022
        </p>
        <p
          style={{ fontFamily: "Kaushan Script" }}
          className="text-lg text-gray-700 my-2"
        >
          @ 5pm
        </p>
        <p style={{ fontFamily: "bad script" }} className="text-xl font-bold">
          South Congress Hotel 🌵 Austin, Texas.
        </p>

        <AddToCalendarDropdown
          event={event}
          buttonText="add to calendar"
          // items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]}
          className="text-sm"
        />
        {/* <AddToCalendar
      event={wedding}
      buttonLabel="add to calendar" /> */}
        {/* <a href="#" style={{fontFamily:"nunito"}}  className="text-sm text-gray-400">add to calendar</a> */}
      </div>

      <p className="my-5 text-sm text-gray-600">
        <Link to="/details/">
          <u>full details</u>
        </Link>{" "}
        |{" "}
        <Link to="/lodging/">
          <u>places to stay</u>
        </Link>
      </p>
      <div className="">
        <Link to="/rsvp/">
          <button
            class="
          bg-red-300
          text-white
          hover:bg-red-400
          font-bold
          uppercase
          pr-8
          pl-4
          rounded-full
          shadow-md
          hover:shadow-lg
          outline-none
          focus:outline-none
          mb-10
          ease-linear
          transition-all
          duration-150
        "
            type="button"
          >
            <p className="inline-flex items-center px-2 pb-1">
              <Lottie className="w-10 mb-1" animationData={invite} />
              <span
                style={{ fontFamily: "nunito" }}
                className="font-medium text-2xl mt-2"
              >
                RSVP
              </span>
            </p>
          </button>
        </Link>

        {/* <Link className="text-3xl float-left" to="/rsvp/">    
          <div className="decoration-none align-middle mx-auto mt-10 border-2 hover:bg-white border-gray-300 py-2 w-60 rounded">
            <Lottie className="float-left -mt-3 w-12" animationData={invite}/><p style={{fontFamily:"nunito",color:"#301f07"}}>RSVP</p>
          </div>
        </Link>
        <Link className="float-right text-3xl" to="/rsvp/">    
          <div className="decoration-none align-middle mx-auto mt-10 border-2 hover:bg-white border-gray-300 py-2 w-60 rounded">
            <Lottie className="float-left -mt-3 w-12" animationData={invite}/><p style={{fontFamily:"nunito",color:"#301f07"}}>RSVP</p>
          </div>
        </Link> */}
      </div>

      <div className="inline-grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
        <StaticImage
          className=""
          src="../images/gallery/23.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/19.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/17.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/IMG_0135.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/IMG_0274.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/IMG_20160821_150238.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/IMG_0338.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/IMG_0341.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/IMG_20160721_200751.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/IMG_20160816_211115.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/IMG_20160822_131236.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/1.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/2.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/3.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/4.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/5.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/6.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
        <StaticImage
          className=""
          src="../images/gallery/7.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/8.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
        <StaticImage
          className=""
          src="../images/gallery/9.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
        <StaticImage
          className=""
          src="../images/gallery/10.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
        <StaticImage
          className=""
          src="../images/gallery/11.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/12.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/13.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/14.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/15.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/16.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/18.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
        <StaticImage
          className=""
          src="../images/gallery/20.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
        <StaticImage
          className=""
          src="../images/gallery/21.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
        <StaticImage
          className=""
          src="../images/gallery/22.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />

        <StaticImage
          className=""
          src="../images/gallery/24.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
        <StaticImage
          className=""
          src="../images/gallery/25.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
        <StaticImage
          className=""
          src="../images/gallery/26.JPG"
          alt="Megan and Corey"
          placeholder="tracedSVG"
          width={300}
          height={300}
        />
      </div>

      <div className="mt-10">
        {/* <Gal /> */}
        {/* <Gallery photos={photos} /> */}
      </div>
    </div>
  </Layout>    </div>

)

export default IndexPage
